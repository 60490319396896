@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;

@tailwind components;

@tailwind utilities;

@layer utilities {
  .text-white-gray {
    color: #c4c4c4;
    opacity: 0.5;
  }

  .min-w-80 {
    width: 20rem;
  }

  .min-w-96 {
    width: 24rem;
  }

  .rounded-15 {
    border-radius: 15px;
  }
}
@font-face {
  font-family: 'gotham';
  src: url('./assets/Gotham-Book.ttf');
}
@font-face {
  font-family: 'gothamBold';
  src: url('./assets/Gotham-Bold.otf');
}
@font-face {
  font-family: 'gothamLight';
  src: url('./assets/Gotham-Light.otf');
}
.gotham_font {
  font-family: 'gotham';
}

.gotham_font_bold {
  font-family: 'gothamBold';
}

.gotham_font_light {
  font-family: 'gothamLight';
}

// tooltip start
.reactTip {
  border-radius: 0.375rem !important;
  padding: 0.44rem 0.75rem !important;
  min-width: 7rem !important;
  opacity: 1 !important;
}

.tooltip-content::after {
  content: ' ';
  position: absolute;
  bottom: 100%; /* This will position the arrow at the top of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent rgba(34, 36, 51, 1) transparent; /* This will make the top border black */
}
.tooltip {
  visibility: invisible;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 50;
}
.__react_component_tooltip::before,
.__react_component_tooltip::after {
  content: none !important;
}
.__react_component_tooltip {
  z-index: 99999;
}

// tooltip start
