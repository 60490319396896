.card-hidden {
    transform: translateX(-100%);
    opacity: 0;
}

.card-visible {
    animation: slideInFromLeft 1s ease-out forwards;
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideUpFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}